


























































import Vue from "vue";
export default Vue.extend({
  props: ["value"],
  data: function() {
    return {
      name: "",
      description: "",
      shuffle: false,
      isPublic: false
    };
  },
  methods: {
    updateValues(): void {
      this.$emit("input", {
        name: this.$data.name,
        description: this.$data.description,
        shuffle: this.$data.shuffle,
        isPublic: this.$data.isPublic
      });
    }
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        (this.$data.name = this.value.name),
          (this.$data.description = this.value.description);
        this.$data.shuffle = this.value.shuffle;
        this.$data.isPublic = this.value.isPublic;
      }
    }
  }
});
