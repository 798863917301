async function generateCodeVerifier(length: number): Promise<string> {
  const characters = "qwertyuiopasdfghjklzxcvbnm1234567890";
  let byteArray = new Uint8Array(length);
  byteArray = window.crypto.getRandomValues(byteArray);
  const letterArray: string[] = [];
  byteArray.forEach(value => {
    letterArray.push(characters[value % characters.length]);
  });
  return letterArray.join("");
}

async function sha256(plain: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);

  return await window.crypto.subtle.digest("SHA-256", data);
}

function base64urlencode(a: ArrayBuffer) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(a) as any))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

// 0 is code verifier
// 1 is code challenge
//2 is state
export default async function generateCodes(): Promise<string[]> {
  const verifier = await generateCodeVerifier(50);
  return [
    verifier,
    base64urlencode(await sha256(verifier)),
    await generateCodeVerifier(10)
  ];
}
