








































































import Vue from "vue";
export default Vue.extend({
  data: function() {
    return {
      showMenu: false
    };
  },
  methods: {
    /**
     * Go Back
     * This function triggers navigation back when clicked. If the user has no history. Go back to the dashboard
     */
    goBack(): void {
      if (window.history.length > 2) {
        this.$router.go(-1);
      } else {
        this.$router.push("/dashboard");
      }
    },
    logout(): void {
      //Replacing the url directly will clear the store. Logging the user out
      window.location.replace(window.location.origin);
    }
  }
});
