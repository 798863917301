

































































import Loading from "@/components/Common/Loading.vue";
import ArtistSelector from "@/components/CreatePlaylist/ArtistSelector.vue";
import BasicDetails from "@/components/CreatePlaylist/BasicDetails.vue";
import Vue from "vue";
import executeSave from "@/functions/createPlaylist/executeSave";

export default Vue.extend({
  // eslint-disable-next-line vue/no-unused-components
  components: { BasicDetails, ArtistSelector, Loading },
  data: function() {
    return {
      step: 0,
      playlistDescription: {
        name: "",
        description: "",
        shuffle: false,
        isPublic: false
      },
      selectedArtists: [],
      error: false,
      errorMessage: ""
    };
  },
  computed: {
    currentTitle(): string {
      switch (this.$data.step) {
        case 0:
          return "Description";
        case 1:
          return "Choose Artists";
        case 2:
          return "Creating Playlist";
        default:
          return "Create Playlist";
      }
    },
    currentSubtitle(): string {
      switch (this.$data.step) {
        case 0:
          return "Just a few things we need to know about your playlist";
        case 1:
          return "Select the artists you would like to build your playlist with";
        case 2:
          return "Completing your playlist";
        default:
          return "Description";
      }
    }
  },
  methods: {
    updateSelectedArtists(val: any) {
      this.$data.selectedArtists = val;
    },
    validateEverything(): number {
      this.$data.error = false;
      this.$data.errorMessage = "";
      if (this.$data.playlistDescription.name === "") {
        this.$data.error = true;
        this.$data.errorMessage = "You need a name for your playlist";
        return 0;
      }
      if (this.$data.selectedArtists.length === 0) {
        this.$data.error = true;
        this.$data.errorMessage =
          "You need to select artists for your playlist";
        return 1;
      }
      return 10;
    },
    async next(): Promise<void> {
      this.$data.error = false;
      this.$data.errorMessage = "";
      if (this.$data.step === 0) {
        this.$data.step = 1;
        return;
      }
      if (this.$data.step === 1) {
        const validate = this.validateEverything();
        if (validate !== 10) {
          this.$data.step = validate;
          return;
        }

        this.$data.step = 2;
        try {
          const response = await executeSave(
            this.$store.state.accessToken,
            this.$data.playlistDescription,
            this.$data.selectedArtists,
            this.$store.state.user.id
          );
          await this.$router.push("/exit/" + response);
        } catch (error) {
          this.$data.error = true;
          this.$data.errorMessage = "Unable to create Playlist";
        }
      }
    },
    back(): void {
      if (this.$data.step === 0) {
        return;
      }
      this.$data.step -= 1;
    }
  },
  mounted(): void {
    this.$store.commit("setCurrentTitle", "Create Playlist");
  }
});
