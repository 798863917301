



































































































import Vue from "vue";
export default Vue.extend({
  mounted(): void {
    this.$store.commit("setCurrentTitle", "Dashboard");
  },
  computed: {
    time: () => {
      const hour = new Date().getHours();
      if (hour >= 0 && hour < 12) {
        return "morning";
      } else if (hour >= 12 && hour < 18) {
        return "afternoon";
      } else {
        return "evening";
      }
    }
  }
});
