









import Vue from "vue";
import Banner from "./components/Common/Banner.vue";

export default Vue.extend({
  name: "App",

  components: {
    Banner
  },
  watch: {
    '$route':{
      handler: (to, from) => {
        document.title = to.meta.title || "Simplify Playlists"
      },
      immediate: true
    }
  },
  data: () => ({
    //
  })
});
