






















import Vue from "vue";
import obtainUser from "@/functions/auth/verifyandRequestUserObject";
import Loading from "@/components/Common/Loading.vue";
import Error from "@/components/Common/Error.vue";
import getRealUser from "@/functions/auth/exchangeRawUserForAuthenticatedUser";
export default Vue.extend({
  components: {
    Loading,
    Error
  },
  data: function() {
    return {
      error: false
    };
  },
  async mounted(): Promise<void> {
    try {
      const user = await obtainUser(this.$route.query);
      const realUser = await getRealUser(user);

      this.$store.commit("setUser", realUser.user);
      this.$store.commit("setAccessToken", realUser.accessToken);
      await this.$router.push("/dashboard");
    } catch (error) {
      this.$data.error = true;
    }
  }
});
