import axios from "axios";
import { constants } from "../Constants/constants";
import { User } from "./models";

export default async function exchange(rawArgs: any): Promise<any> {
  const potentialUser: User = {
    id: rawArgs.id,
    email: rawArgs.email,
    image: rawArgs.images[0].url,
    firstName: rawArgs.display_name.split(" ")[0],
    lastName: rawArgs.display_name.split(" ")[
      rawArgs.display_name.split(" ").length - 1
    ],
    profileUrl: rawArgs.href
  };

  const response = await axios.post(
    constants.BACKENDURL + "/getUser",
    {
      user: potentialUser,
      accessToken: rawArgs.accessToken
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  if (response.data.success) {
    return {
      user: potentialUser,
      accessToken: rawArgs.accessToken
    };
  }
  throw new Error("error saving user");
}
