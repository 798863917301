















































import Vue from "vue";
export default Vue.extend({
  props: {
    heading: {
      required: true,
      type: String
    },
    message: {
      required: true,
      type: String
    },
    indeterminate: {
      required: true,
      type: Boolean
    },
    progress: Number,
    progressColor: {
      default: "primary",
      type: String
    }
  }
});
