



















































import Axios from "axios";
import Vue from "vue";
export default Vue.extend({
  props: {
    image: String,
    name: String,
    artistIds: Array,
    id: String,
    description: String,
    isOpen: Boolean
  },
  data: function() {
    return {
      artists: []
    };
  },
  methods: {
    async getArtists(): Promise<any> {
      const url = "https://api.spotify.com/v1/artists";
      const response = await Axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + this.$store.state.accessToken
        },
        params: {
          ids: this.$props.artistIds.join(",")
        }
      });
      this.$data.artists = (response.data.artists as Array<any>).map(value => {
        return {
          name: value.name,
          id: value.id,
          image: value.images.map((value: { url: any }) => value.url)[0]
        };
      });
    }
  },
  watch: {
    isOpen: function(isOpen: boolean) {
      if (isOpen) {
        this.getArtists();
      }
    }
  }
});
