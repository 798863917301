
















































































































































import NoPlaylist from "@/components/ViewingPlaylists/NoPlaylist.vue";
import PlaylistViewCard from "@/components/ViewingPlaylists/PlaylistViewCard.vue";
import { getPlaylists } from "@/functions/viewPlaylists/getPlaylists";
import { deletePlaylist } from "@/functions/viewPlaylists/deletePlaylist";
import Vue from "vue";
export default Vue.extend({
  components: { NoPlaylist, PlaylistViewCard },
  data: function() {
    return {
      error: false,
      errorMessage: "",
      playlists: [],
      isLoading: true,
      searchParams: "",
      index: 0,
      showDialog: false,
      selectedPlaylist: {}
    };
  },
  methods: {
    async getThePlaylists(): Promise<void> {
      try {
        this.setLoading(true);
        this.clearError();
        const response = await getPlaylists(
          this.$store.state.accessToken,
          this.$store.state.user.id
        );
        this.$data.playlists = response.playlists;
        this.setLoading(false);
      } catch (error) {
        this.setLoading(false);
        this.setError("Unable to get playlists");
      }
    },
    setLoading(val: boolean): void {
      this.$data.isLoading = val;
    },
    setError(message: string): void {
      this.$data.error = true;
      this.$data.errorMessage = message;
    },
    clearError(): void {
      this.$data.error = false;
      this.$data.errorMessage = "";
    },
    openDialog(index: number): void {
      this.$data.selectedPlaylist = this.searchResults[index];
      this.$data.showDialog = true;
    },
    gotoEditPlaylist(id: string) {
      this.$router.push("playlists/edit/" + id);
    },
    async deletePlaylist(index: number) {
      this.$data.showDialog = false;
      this.$data.selectedPlaylist = {};
      this.setLoading(true);
      this.clearError();
      const playlist = this.searchResults[index];
      try {
        const response = await deletePlaylist(
          playlist.id,
          this.$store.state.user.id,
          this.$store.state.accessToken
        );
        if (response.success) {
          await this.getThePlaylists();
        } else {
          throw "";
        }
      } catch (error) {
        this.setLoading(false);
        this.setError("Unable to delete playlist");
      }
    }
  },
  async mounted(): Promise<void> {
    this.$store.commit("setCurrentTitle", "Playlists");
    await this.getThePlaylists();
  },
  computed: {
    searchResults(): Array<any> {
      if (this.$data.searchParams.length === 0) {
        return this.$data.playlists as Array<any>;
      }
      return (this.$data.playlists as Array<any>).filter(value => {
        return value.name.toLowerCase().startsWith(this.$data.searchParams);
      });
    }
  }
});
