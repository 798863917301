import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentTitle: "",
    user: {},
    accessToken: ""
  },
  mutations: {
    setCurrentTitle(state, payload) {
      state.currentTitle = payload;
    },
    setUser(state, payload): void {
      state.user = payload;
    },
    setAccessToken(state, payload): void {
      state.accessToken = payload;
    }
  },
  actions: {},
  modules: {},
  getters: {
    isAuthenticated: state => {
      return state.accessToken !== "";
    }
  }
});
