import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Landing from "@/views/Landing.vue";
import Authorisation from "@/views/Authorisation.vue";
import Dashboard from "@/views/Dashboard.vue";
import CreateWizard from "@/views/CreatePlaylistWizard.vue";
import EditWizard from "@/views/EditPlaylistWizard.vue";
import Exit from "@/views/Exit.vue";
import Playlists from "@/views/Playlists.vue";
import User from "../store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Landing,
    meta: {
      auth: false,
      title: "Simplify Playlists - Automatic Spotify playlist generation"
    }
  },
  {
    path: "/authorisation",
    name: "Authorisation",
    component: Authorisation,
    meta: {
      auth: false,
      title: "Simplify Playlists - Login"
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      auth: true,
      title: "Simplify Playlists - Dashboard"
    }
  },
  {
    path: "/createplaylist",
    name: "Create Playlist",
    component: CreateWizard,
    meta: {
      auth: true,
      title: "Simplify Playlists - Create a new Playlist"
    }
  },
  {
    path: "/exit/:id",
    name: "Exit",
    component: Exit,
    meta: {
      auth: true,
      title: "Simplify Playlists"
    }
  },
  {
    path: "/playlists",
    name: "Playlists",
    component: Playlists,
    meta: {
      auth: true,
      title: "Simplify Playlists - My Playlists"
    }
  },
  {
    path: "/playlists/edit/:id",
    name: "Edit Playlists",
    component: EditWizard,
    meta: {
      auth: true,
      title: "Simplify Playlists - Edit Playlist"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.auth)) {
    if (User.getters.isAuthenticated) {
      next();
    } else {
      next({ path: "/" });
    }
  }
  next();
});

export default router;
