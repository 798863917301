/* eslint-disable @typescript-eslint/camelcase */
import { constants } from "../Constants/constants";
import axios from "axios";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const qs = require("query-string");
function verifyandReturnCode(params: any) {
  if (params.error) {
    throw new Error();
  }
  const clientState: string = localStorage.getItem("state") || "";
  if ((params.state as string) !== clientState) {
    console.log("invalid state. Potential csrf");
    throw new Error();
  }
  return params.code;
}

async function obtainTokens(code: string) {
  const codeVerifier = localStorage.getItem("codeVerifier");
  if (codeVerifier === null) {
    console.log("No Code Verifier Saved");
    throw new Error();
  }
  const args = {
    client_id: constants.SPOTIFY_CLIENT_ID,
    grant_type: "authorization_code",
    code: code,
    redirect_uri: window.location.origin + "/authorisation",
    code_verifier: codeVerifier
  };
  const response = await axios.post(
    "https://accounts.spotify.com/api/token",
    qs.stringify(args),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  );
  return response.data;
}

async function getUserObject(params: any) {
  const response = await axios.get("https://api.spotify.com/v1/me", {
    headers: {
      Authorization: "Bearer " + params.access_token
    }
  });
  return response.data;
}

export default async function obtainUserFromRedirect(params: any) {
  const code = verifyandReturnCode(params);
  const tokens = await obtainTokens(code);
  const user = await getUserObject(tokens);
  user.accessToken = tokens.access_token;
  return user;
}
