


















































import Vue from "vue";
export default Vue.extend({
  props: {
    title: String,
    message: String,
    isBtnVisible: Boolean,
    BtnText: String,
    href: {
      required: true,
      type: String
    }
  },
  methods: {
    performRoute(): void {
      this.$router.push(this.$props.href);
    }
  }
});
