

















































import Vue from "vue";
export default Vue.extend({
  methods: {
    gotoCreatePlaylist(): void {
      this.$router.push("/createplaylist");
    }
  }
});
