import Axios from "axios";
import { constants } from "../Constants/constants";

interface PlaylistDetails {
  id: string;
  name: string;
  description: string;
  isPublic: boolean;
  shuffle: boolean;
  artistIds: string[];
  createdBy: string;
}

export default async function executeSave(
  accessToken: string,
  playlistDescription: any,
  selectedArtistIDs: any[],
  userID: string
) {
  const data: PlaylistDetails = {
    id: "",
    name: playlistDescription.name,
    description: playlistDescription.description,
    isPublic: playlistDescription.isPublic,
    shuffle: playlistDescription.shuffle,
    artistIds: selectedArtistIDs.map(value => value.id),
    createdBy: ""
  };
  const response = await Axios.post(constants.BACKENDURL + "/createPlaylist", {
    data: data,
    userID: userID,
    accessToken: accessToken
  });
  return response.data.playlistId;
}
