import Axios from "axios";
import { constants } from "../Constants/constants";

export async function updatePlaylist(
  accessToken: string,
  playlistDescription: any,
  userID: string,
  playlistID: string,
  selectedArtistIDs: any[]
) {
  const data: PlaylistDetails = {
    id: playlistID,
    name: playlistDescription.name,
    description: playlistDescription.description,
    isPublic: playlistDescription.isPublic,
    shuffle: playlistDescription.shuffle,
    artistIds: selectedArtistIDs.map(value => value.id),
    createdBy: userID
  };
  const response = await Axios.post(constants.BACKENDURL + "/updatePlaylist", {
    data: data,
    accessToken: accessToken
  });
  return response.data;
}

interface PlaylistDetails {
  id: string;
  name: string;
  description: string;
  isPublic: boolean;
  shuffle: boolean;
  artistIds: string[];
  createdBy: string;
}
