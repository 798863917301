import Axios from "axios";
import { constants } from "../Constants/constants";

export async function getPlaylists(accessToken: string, userID: string) {
  const response = await Axios.post(constants.BACKENDURL + "/getPlaylists", {
    accessToken: accessToken,
    userID: userID
  });
  return response.data;
}

export async function getAPlaylist(userID: string, playlistID: string) {
  const response = await Axios.post(constants.BACKENDURL + "/getPlaylist", {
    userID: userID,
    playlistID: playlistID
  });
  return response.data;
}
