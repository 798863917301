/* eslint-disable @typescript-eslint/camelcase */
import { constants } from "../Constants/constants";
import generateCodes from "./generateCodes";

export function buildAuthObject(codeChallenge: string, state: string) {
  return {
    client_id: constants.SPOTIFY_CLIENT_ID,
    response_type: "code",
    redirect_uri: window.location.origin + "/authorisation",
    code_challenge_method: "S256",
    code_challenge: codeChallenge,
    state: state,
    scope: [
      "user-read-email",
      "user-read-private",
      "playlist-read-private",
      "playlist-modify-private",
      "playlist-modify-public",
      "user-top-read",
      "user-follow-read"
    ].join(" ")
  };
}

export async function generateQueryArgs(): Promise<string> {
  const codes = await generateCodes();
  const authObject = buildAuthObject(codes[1], codes[2]);
  //Save the codeVerifier for use later when requesting code
  localStorage.setItem("codeVerifier", codes[0]);
  localStorage.setItem("state", codes[2]);
  let query = "";
  for (const [key, value] of Object.entries(authObject)) {
    query += key + "=" + value + "&";
  }
  return query.substring(0, query.length - 1);
}

export async function obtainCodethroughRedirect() {
  window.location.replace(
    "https://accounts.spotify.com/authorize?" + (await generateQueryArgs())
  );
}
