
























































import Vue from "vue";
import { obtainCodethroughRedirect } from "@/functions/auth/ObtainCode";
export default Vue.extend({
  data: function() {
    return {};
  },
  methods: {
    startAuth(): void {
      obtainCodethroughRedirect();
    }
  },
  mounted(): void {
    this.$store.commit("setCurrentTitle", "Home");
  }
});
