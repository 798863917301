import Axios from "axios";

async function getArtistIdsFromRecommendations(
  accessToken: string,
  artistIds: string[]
) {
  const params = [
    "limit=5",
    "market=ES",
    "seed_artists=" + artistIds.splice(artistIds.length - 4, 3).join("%2C")
  ].join("&");
  const response = await Axios.get(
    "https://api.spotify.com/v1/recommendations?" + params,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken
      }
    }
  );
  const result = (response.data.tracks as Array<any>).map(value => {
    return (value.artists as Array<any>).map(value => value.id);
  });
  const realIDs: any[] = [];
  result.forEach(value => {
    value.forEach(val => {
      if (!realIDs.includes(val)) {
        //Flatten array with only unique ids
        realIDs.push(val);
      }
    });
  });
  return realIDs;
}

async function getArtistDetails(accessToken: string, artistIds: any[]) {
  const response = await Axios.get("https://api.spotify.com/v1/artists", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + accessToken
    },
    params: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      ids: artistIds.join(",")
    }
  });
  return (response.data.artists as Array<any>).map(artist => {
    return {
      name: artist.name,
      id: artist.id,
      image: artist.images[0] ? artist.images[0].url : ""
    };
  });
}

export default async function predictNewArtists(
  accessToken: string,
  initialIds: string[]
) {
  return await getArtistDetails(
    accessToken,
    await getArtistIdsFromRecommendations(accessToken, initialIds)
  );
}
