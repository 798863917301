




























































import Vue from "vue";
export default Vue.extend({
  methods: {
    gotoPlaylist(): void {
      window.location.replace(
        "https://open.spotify.com/playlist/" + this.$route.params.id
      );
    }
    //TODO: Share Button
  }
});
