import Axios from "axios";

export default async function executeSearch(
  accessToken: string,
  params: string
): Promise<any[]> {
  const response = await Axios.get("https://api.spotify.com/v1/search", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + accessToken
    },
    params: {
      q: params,
      type: "artist"
    }
  });
  return (response.data.artists.items as Array<any>).map(value => {
    return {
      name: value.name,
      image: value.images[0] ? value.images[0].url : undefined,
      id: value.id
    };
  });
}
