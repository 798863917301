import Axios from "axios";
import { constants } from "../Constants/constants";

export async function deletePlaylist(
  playlistID: string,
  userID: string,
  accessToken: string
) {
  const response = await Axios.post(constants.BACKENDURL + "/deletePlaylist", {
    accessToken: accessToken,
    userID: userID,
    playlistID: playlistID
  });
  return response.data;
}
