

































































































import Vue from "vue";
import getInitialSeed from "@/functions/createPlaylist/getSeed";
import executeSearch from "@/functions/createPlaylist/executeSearch";
import predictNewArtists from "@/functions/createPlaylist/predictNewArtists";
export default Vue.extend({
  data: function() {
    return {
      selectedArtists: [],
      searchResults: [],
      seededValues: {
        following: [],
        popular: [],
        suggested: []
      },
      categorySelected: 0,
      isLoading: false,
      error: false,
      errorMessage: "",
      searchExecutor: null
    };
  },
  computed: {
    realSeed(): Array<any> {
      switch (this.$data.categorySelected) {
        case 0:
          return this.$data.seededValues.suggested;
        case 1:
          return this.$data.seededValues.following;
        case 2:
          return this.$data.seededValues.popular;
        default:
          return this.$data.seededValues.popular;
      }
    }
  },
  methods: {
    async getSeed(): Promise<void> {
      try {
        const response = await getInitialSeed(
          this.$store.state.accessToken,
          30
        );

        this.$data.seededValues.popular = response.result[0];
        this.$data.seededValues.suggested = response.result[1];
        this.$data.seededValues.following = response.result[2];
      } catch (error) {
        //
        this.setError("An Internal Error Occured. Please try again Later");
      }
    },
    setLoading(val: boolean): void {
      this.$data.isLoading = val;
    },
    setError(message: string): void {
      this.$data.error = true;
      this.$data.errorMessage = message;
    },
    clearError(): void {
      this.$data.error = false;
      this.$data.errorMessage = "";
    },
    setInitialScreen(): void {
      //Sets the first screen according to which data has the most artists
      if ( this.$data.seededValues.suggested.length > 10 ) {
        this.$data.categorySelected = 0;
      } else if (
        this.$data.seededValues.following.length > 10
      ) {
        this.$data.categorySelected = 1;
      } else {
        this.$data.categorySelected = 2;
      }
    },
    async updatePlaylistFromSeed(index: number): Promise<void> {
      switch (this.$data.categorySelected) {
        case 0: {
          this.$data.selectedArtists.push(
            (this.$data.seededValues.suggested as Array<any>).splice(
              index,
              1
            )[0]
          );
          break
          
        }
        case 1: {
          this.$data.selectedArtists.push(
            (this.$data.seededValues.following as Array<any>).splice(
              index,
              1
            )[0]
          );
          break
        }
        case 2: {
          this.$data.selectedArtists.push(
            (this.$data.seededValues.popular as Array<any>).splice(index, 1)[0]
          );
          break
        }
      }
      this.$emit("onSelectedArtistChange", this.$data.selectedArtists);
    },
    removeArtist(i: number): void {
      this.$data.selectedArtists.splice(i, 1);
      this.$emit("onSelectedArtistChange", this.$data.selectedArtists);
    },
    async searchFunction(params: string) {
      this.clearError();
      if (params.length === 0) {
        this.$data.seededValues.suggested = this.$data.seededValues.popular;
        return;
      }

      try {
        this.setLoading(true);
        this.$data.categorySelected = 0;
        const result = await executeSearch(
          this.$store.state.accessToken,
          params
        );
        this.$data.seededValues.suggested = result;
        this.setLoading(false);
      } catch (error) {
        console.log(error);
        this.setLoading(false);
        this.setError("An error occured while searching");
      }
    },
    async predictArtists(): Promise<any[]> {
      if (this.$data.selectedArtists.length > 5) {
        try {
          const response = await predictNewArtists(
            this.$store.state.accessToken,
            this.$data.selectedArtists.map((value: { id: any }) => value.id)
          );
          console.log(response);
          return response;
        } catch (error) {
          console.log(error.response);
          return [];
        }
      }
      return [];
    }
  },
  async mounted(): Promise<void> {
    this.clearError();
    this.setLoading(true);
    await this.getSeed();
    this.setInitialScreen();
    this.setLoading(false);
  }
});
