import axios from "axios";
import { constants } from "../Constants/constants";

export default async function getSeed(accessToken: string, limit: number) {
  const response = await axios.post(
    constants.BACKENDURL + "/getInitialSeed",
    {
      accessToken: accessToken,
      limit: limit
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  return response.data;
}
